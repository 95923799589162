import React, { FC, useState } from "react";

import type { MarkerProps } from "@react-google-maps/api";
import { Marker } from "@react-google-maps/api";

import { FinderStation } from "../../store";
import { GasStationOwner } from "../../types/index";
import * as StationType from "../../types/StationType";
import hoyer from "./tankstelle-hoyer.svg";
import other from "./tankstelle-other.svg";
import travis from "./travis.svg";

const icons = {
    hoyer,
    other,
    travis,
};

const gasStationTypesToIcons = {
    [GasStationOwner.hoyer]: "hoyer",
    [GasStationOwner.partner]: "hoyer",
    [GasStationOwner.acceptancePartner]: "other",
} as Record<GasStationOwner, keyof typeof icons>;

const icon = (t: StationType.StationType) => {
    if (StationType.stationTypeIsGasStation(t))
        return icons[gasStationTypesToIcons[t.owner]];

    return icons.travis;
};

export type StationMarkerProps = {
    gasStation: FinderStation;
    selected: boolean;
} & Omit<
    MarkerProps,
    "position" | "opacity" | "onMouseOver" | "onMouseOut" | "icon"
>;

const StationMarker: FC<StationMarkerProps> = ({
    gasStation,
    selected,
    ...props
}) => {
    const [hovered, setHovered] = useState<boolean>(false);

    return (
        <Marker
            position={gasStation.geo}
            opacity={selected || hovered ? 0.8 : 1}
            onMouseOver={() => setHovered(true)}
            onMouseOut={() => setHovered(false)}
            icon={icon(gasStation.type)}
            {...props}
        />
    );
};
export default StationMarker;
