import React, { FC } from "react";

import type { InfoWindowProps as InfoWindowProps_ } from "@react-google-maps/api";
import { InfoWindow as InfoWindow_ } from "@react-google-maps/api";

import { css, useTheme } from "@emotion/react";

import cls from "../../../../utils/cls";
import { IconPredefined } from "../../../shared/icons/Icon";
import { PredefinedIconType } from "../../../shared/icons/Icon/Predefined";
import * as styles from "./style.module.scss";

export type InfoWindowProps = {
    containerClassName?: string;
    onCloseClick?: () => void;
} & InfoWindowProps_;

const InfoWindow: FC<InfoWindowProps> = ({
    containerClassName,
    children,
    onCloseClick,
    ...props
}) => {
    const theme = useTheme();

    const closeIconStyles = css`
        position: absolute;
        top: 20px;
        right: 20px;
        z-index: 100;
        cursor: pointer;
    `;
    return (
        <InfoWindow_ {...props}>
            <div className={cls(styles.InfoWindow, containerClassName)}>
                {onCloseClick && (
                    <IconPredefined
                        css={closeIconStyles}
                        type={PredefinedIconType.close}
                        color={theme.colors.blue.toString()}
                        onClick={onCloseClick}
                    />
                )}
                {children}
            </div>
        </InfoWindow_>
    );
};
export default InfoWindow;
