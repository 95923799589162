import React, { FC } from "react";

import styled from "@emotion/styled";

import GoogleMapsFontsConsent from "../../../shared/GoogleMapsFontsConsent";
import backgroundImage from "./screenshot.jpg";

const ConsentBackgroundContainer = styled.div`
    position: relative;
    min-height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(${backgroundImage});
    background-position: center;
    background-size: cover;
`;

const ConsentDarkener = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
`;

type ConsentProps = Record<string, never>;

const Consent: FC<ConsentProps> = () => (
    <ConsentBackgroundContainer>
        <GoogleMapsFontsConsent />
        <ConsentDarkener />
    </ConsentBackgroundContainer>
);
export default Consent;
