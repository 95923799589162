import React, { FC } from "react";

import * as St from "fp-ts/lib/ReadonlySet";

import { usePushToDataLayer } from "../../../../utils/analytics";
import Headline from "../../../shared/Headline";
import RouteLink from "../RouteLink";
import { FinderTravisStation } from "../store";
import { NormalServiceId, TravisServiceId } from "../types/index";
import * as NormalOrTravis from "../types/NormalOrTravis";
import * as Property from "../types/Property";
import InfoWindow, { InfoWindowProps } from "./InfoWindow";
import * as styles from "./style.module.scss";

const daysOfWeek = [
    { id: "https://schema.org/Monday", name: "Mo" },
    { id: "https://schema.org/Tuesday", name: "Di" },
    { id: "https://schema.org/Wednesday", name: "Mi" },
    { id: "https://schema.org/Thursday", name: "Do" },
    { id: "https://schema.org/Friday", name: "Fr" },
    { id: "https://schema.org/Saturday", name: "Sa" },
    { id: "https://schema.org/Sunday", name: "So" },
    // { id: "https:schema.org/PublicHolidays", name: "Feiertage" },
];

const properties: { id: Property.PropertyId; name: string }[] = [
    {
        id: Property.servicePropertyId(
            NormalOrTravis.normal_(8 as NormalServiceId)
        ),
        name: "LKW Wäsche",
    },
    {
        id: Property.servicePropertyId(
            NormalOrTravis.travis_(TravisServiceId.tankCleaning)
        ),
        name: "Tankreinigung",
    },
    {
        id: Property.servicePropertyId(
            NormalOrTravis.normal_(13 as NormalServiceId)
        ),
        name: "Werkstatt",
    },
    // {
    //     id: Property.servicePropertyId(
    //         NormalOrTravis.travis_(3),
    //     ),
    //     name: "Autowäsche",
    // },
    {
        id: Property.servicePropertyId(
            NormalOrTravis.normal_(9 as NormalServiceId)
        ),
        name: "Parken",
    },
];

type OpeningHoursSpecificationProps = {
    opens: string;
    closes: string;
};

const OpeningHoursSpecification: FC<OpeningHoursSpecificationProps> = ({
    opens,
    closes,
}) => (
    <span>
        {opens}–{closes}
    </span>
);

export type TravisInfoWindowProps = {
    gasStation: FinderTravisStation;
} & InfoWindowProps;

const TravisInfoWindow: FC<TravisInfoWindowProps> = ({
    gasStation,
    ...props
}) => {
    usePushToDataLayer(
        "gasStations:finder:infoWindow:open",
        {
            station: {
                type: "travis",
                id: `travis:${gasStation.id.value}`,
            },
        },
        [gasStation]
    );

    return (
        <InfoWindow {...props} containerClassName={styles.TravisInfoWindow}>
            {gasStation !== null && (
                <>
                    <Headline>{gasStation.name}</Headline>

                    <div className={styles.TravisInfoWindow__contents}>
                        <address>
                            {gasStation.contactPoint.telephone && (
                                <div>
                                    Tel.: {gasStation.contactPoint.telephone}
                                </div>
                            )}

                            <div>{gasStation.address.streetAddress}</div>

                            <div>
                                {gasStation.address.postalCode}{" "}
                                {gasStation.address.locality}
                            </div>
                        </address>

                        <table>
                            <tbody>
                                {daysOfWeek.map(({ id, name }) => {
                                    const entry =
                                        gasStation.contactPoint.hoursAvailable.find(
                                            ({ dayOfWeek }) => dayOfWeek === id
                                        );

                                    return (
                                        <tr key={id}>
                                            <th>{name}:</th>
                                            <td>
                                                {entry ? (
                                                    <OpeningHoursSpecification
                                                        {...entry}
                                                    />
                                                ) : (
                                                    "Geschlossen"
                                                )}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>

                        <table>
                            <tbody>
                                {properties.map((prop) => (
                                    <tr
                                        key={
                                            Property.propertyIdIsTravisPropertyId(
                                                prop.id
                                            )
                                                ? "travis"
                                                : `${prop.id.type}-${prop.id.value.type}-${prop.id.value.value}`
                                        }
                                    >
                                        <th>{prop.name}:</th>
                                        <td>
                                            {St.elem(Property.IdOrd)(
                                                prop.id,
                                                gasStation.availableProperties
                                            )
                                                ? "Ja"
                                                : "Nein"}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    <RouteLink
                        {...gasStation.address}
                        className={styles.TravisInfoWindow__routeLink}
                        data-gtag="travis:routeGoogleMaps"
                    />
                </>
            )}
        </InfoWindow>
    );
};
export default TravisInfoWindow;
