import React, { FC } from "react";

import FormattedDuration from "react-intl-formatted-duration";

import InfoWindow from "./InfoWindow";
import * as styles from "./style.module.scss";

export type DurationInfoWindowProps = {
    directions: google.maps.DirectionsResult;
};

const DurationInfoWindow: FC<DurationInfoWindowProps> = ({ directions }) => {
    const steps = directions.routes[0].legs[0].steps;
    const location = steps[Math.floor(steps.length / 2)];
    const { duration, distance } = directions.routes[0].legs[0];

    return (
        <InfoWindow
            position={{
                lat: location.start_location.lat(),
                lng: location.start_location.lng(),
            }}
            containerClassName={styles.RouteInfoWindow}
        >
            <div id="duration-window">
                {duration && (
                    <FormattedDuration
                        unitDisplay="short"
                        format="{hours} {minutes}"
                        seconds={duration.value}
                    />
                )}

                {duration && distance && <br />}

                {distance && distance.text}
            </div>
        </InfoWindow>
    );
};

export default DurationInfoWindow;
