import React, { FC, useContext } from "react";

import { graphql, PageProps } from "gatsby";

import Finder from "../../components/pages/GasStations/Finder";
import Consent from "../../components/pages/GasStations/Finder/Consent";
import { DataContext as CookieConsentContext } from "../../providers/CookieConsentProvider";

type FinderPageProps = PageProps;

const FinderPage: FC<FinderPageProps> = ({ location }) => {
    const {
        data: { accepted, acceptedGoogleMapsAndFonts },
    } = useContext(CookieConsentContext);

    if (!accepted || !acceptedGoogleMapsAndFonts) {
        return <Consent />;
    }
    return <Finder {...{ location }} />;
};
export default FinderPage;

export const query = graphql`
    query {
        page: pagesYaml(templateKey: { eq: "pages/finder" }) {
            ...Page
        }
    }
`;
