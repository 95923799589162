import React, { FC } from "react";

import { useTheme } from "@emotion/react";

import RouteIcon from "../../../shared/icons/RouteIcon";
import { PostalAddress } from "../types/index";
import * as styles from "./style.module.scss";

export type RouteLinkProps = Omit<PostalAddress, "country"> & {
    country?: PostalAddress["country"];
} & Omit<JSX.IntrinsicElements["a"], "href">;

const RouteLink: FC<RouteLinkProps> = ({
    className = "",
    streetAddress,
    postalCode,
    locality,
    country,
    ...props
}) => {
    const theme = useTheme();
    return (
        <a
            className={`${styles.RouteLink} ${className}`}
            href={`https://www.google.com/maps?daddr=${streetAddress},${postalCode},${locality},${
                country ?? ""
            }`}
            target="_blank"
            rel="noopener noreferrer"
            data-gtag="gasStations:routeGoogleMaps"
            {...props}
        >
            <RouteIcon color={theme.colors.blue.toString()} />
            <div className={styles.RouteLink__Text}>Routenplaner</div>
        </a>
    );
};
export default RouteLink;
