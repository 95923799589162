const mapStyles = Object.entries({
    "poi.business": {
        default: {
            stylers: [
                {
                    visibility: "off",
                },
            ],
        },
    },
    "poi.medical": {
        default: {
            stylers: [
                {
                    visibility: "off",
                },
            ],
        },
    },
}).flatMap(([featureType, featureSettings]) =>
    Object.entries(featureSettings).flatMap(([elementType, settings]) => [
        {
            ...(featureType === "default" ? {} : { featureType }),
            ...(elementType === "default" ? {} : { elementType }),
            ...settings,
        },
    ]),
);

export default mapStyles;
