import React, { FC } from "react";

import type { PropsOf } from "@emotion/react";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";

import DeleteIcon from "../../../shared/icons/DeleteIcon";
import useGasStations from "../store";
import redCornerLeft from "./red_corner_left.svg";

const Flag = styled.div`
    position: fixed;
    top: 25vh;
    right: -26px;
    z-index: 100;
    height: 34px;
    padding: 5px 15px 5px 0;
    font-family: var(--font-family-vito);
    font-size: 1.2em;
    color: ${({ theme }) => theme.colors.white.toString()};
    background-color: ${({ theme }) => theme.colors.red.toString()};
    border-top-right-radius: 15px;
    transform: rotate(-90deg);

    &:hover {
        cursor: pointer;
        filter: brightness(85%);
    }

    &::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 71px;
        height: 100%;
        content: "";
        background-image: url("${redCornerLeft}");
        background-repeat: no-repeat;
        background-position: top right;
        transform: translateX(-100%);
    }
`;

const Content = styled.div`
    display: flex;
    align-items: center;

    svg {
        height: 18px;
        margin-right: 5px;
        color: white;
    }
`;

export type ResetFilterProps = PropsOf<typeof Flag>;

const ResetFilter: FC<ResetFilterProps> = (props) => {
    const theme = useTheme();

    const { resetFilter, hasFilter } = useGasStations((state) => ({
        resetFilter: state.resetFilter,
        hasFilter:
            state.filter.fuelTypes.size === 0 &&
            state.filter.properties.size === 0 &&
            state.filter.hoyerStationTypes.size === 0 &&
            state.filter.acceptancePartnerStationTypes.size === 0 &&
            state.filter.circumscribedArea.radius === null,
    }));

    if (hasFilter) {
        return null;
    }
    return (
        <Flag onClick={resetFilter} {...props}>
            <Content>
                <DeleteIcon color={theme.colors.white.toString()} /> Filter
            </Content>
        </Flag>
    );
};

export default ResetFilter;
