import React from "react";

import Icon from "../Icon";
import * as styles from "./style.module.scss";

const pct = (n) =>
    n.toLocaleString(undefined, { style: "percent", maximumFractionDigits: 0 });

const Spinner = ({ min = 0, max = 100, now, ...props }) => {
    const valuenow = now === undefined ? undefined : (now - min) / (max - min);

    return (
        <div
            className={styles.spinner}
            role="progressbar"
            aria-valuemin={0}
            aria-valuemax={100}
            aria-valuenow={
                valuenow === undefined ? undefined : Math.round(valuenow * 100)
            }
            aria-valuetext={valuenow === undefined ? undefined : pct(valuenow)}
            {...props}
        >
            <Icon
                title="Lädt…"
                className={styles.spinnerIcon}
                type="spinnerWhite"
            />
        </div>
    );
};

export default Spinner;
