import * as Sentry from "@sentry/gatsby";

import {
    apiFetch,
    apiFetchProtected,
    ApiResponse,
    errorAwareApiFetch,
    errorAwareApiFetchProtected,
} from "./.";

export type ApiFetcher<req, res> = (req: req) => Promise<ApiResponse<res>>;
export type DataResponse<res> = { data: res };

type JSONValue =
    | null
    | string
    | number
    | boolean
    | { readonly [x: string]: JSONValue }
    | ReadonlyArray<JSONValue>;

export type ToRequestBody = {
    toRequestBody: () => NonNullable<RequestInit["body"]>;
    contentType: () => string;
};

export abstract class BaseData implements ToRequestBody {
    constructor(
        private type: string,
        private subtype: string,
        private suffix?: string
    ) {}

    abstract toRequestBody(): NonNullable<RequestInit["body"]>;

    contentType() {
        return `${this.type}/${[this.subtype, this.suffix]
            .filter(Boolean)
            .join("+")}`;
    }
}

export class JSONData extends BaseData {
    constructor(private data: JSONValue, subtype = "json") {
        super("application", subtype, subtype === "json" ? undefined : "json");
    }

    toRequestBody() {
        return JSON.stringify(this.data);
    }
}

export type UrlPath = string & { readonly _urlPath: "_urlPath" };
export const UrlPath = {
    empty: "" as UrlPath,
    concat: (a: UrlPath, b: UrlPath): UrlPath => (a + b) as UrlPath,
};
export const urlPath = (
    strings: TemplateStringsArray,
    ...values: Array<string | number>
): UrlPath =>
    strings
        .map((s, i) =>
            i === strings.length - 1
                ? s
                : `${s}${encodeURIComponent(values[i])}`
        )
        .join("") as UrlPath;

type RelativeUrlObj = {
    path: UrlPath;
    query: Record<string, string | boolean | number | undefined>;
};
const RelativeUrlObj = {
    concat: (a: RelativeUrlObj, b: RelativeUrlObj): RelativeUrlObj => ({
        path: UrlPath.concat(a.path, b.path),
        query: {
            ...a.query,
            ...b.query,
        },
    }),
    format: ({ path, query }: RelativeUrlObj): string => {
        const filtered = Object.entries(query).filter(
            (entry): entry is [string, string | number] =>
                entry[1] !== undefined
        );

        if (!filtered.length) return path;

        const search = new URLSearchParams();
        filtered.forEach(([k, v]) => search.set(k, String(v)));
        return `${path}?${String(search)}`;
    },
};

type RelativeUrl = UrlPath | RelativeUrlObj;
const RelativeUrl = {
    empty: UrlPath.empty,
    toRelativeUrlObj: (u: RelativeUrl): RelativeUrlObj =>
        typeof u === "string" ? { path: u, query: {} } : u,
    concat: (a: RelativeUrl, b: RelativeUrl) =>
        RelativeUrlObj.concat(
            RelativeUrl.toRelativeUrlObj(a),
            RelativeUrl.toRelativeUrlObj(b)
        ),
    format: (a: RelativeUrl) =>
        typeof a === "string" ? a : RelativeUrlObj.format(a),
};

type MkUrl<req> = RelativeUrl | ((req: req) => RelativeUrl);
const MkUrl = {
    empty: UrlPath.empty as MkUrl<any>,
    concat:
        <reqA, reqB>(a: MkUrl<reqA>, b: MkUrl<reqB>): MkUrl<reqA & reqB> =>
        (req) =>
            RelativeUrl.concat(
                a instanceof Function ? a(req) : a,
                b instanceof Function ? b(req) : b
            ),
    run: <req>(mkUrl: MkUrl<req>, req: req): string =>
        RelativeUrl.format(mkUrl instanceof Function ? mkUrl(req) : mkUrl),
};

type FetcherArgs<req> =
    | [
          mkUrl: MkUrl<req>,
          method: "OPTIONS" | "HEAD" | "GET" | "DELETE",
          mkBody?: (req: req) => undefined | FormData | ToRequestBody,
          init?: Omit<RequestInit, "method" | "body">
      ]
    | [
          mkUrl: MkUrl<req>,
          method: "POST" | "PUT" | "PATCH",
          mkBody: (req: req) => undefined | FormData | ToRequestBody,
          init?: Omit<RequestInit, "method" | "body">
      ];

const mkReq =
    <req, res>(
        f: (
            req: string,
            init: RequestInit & { headers: Headers }
        ) => Promise<res>
    ) =>
    (
        ...[
            mkUrl,
            method,
            mkBody = () => undefined,
            init = {},
        ]: FetcherArgs<req>
    ) =>
    async (req: req) => {
        const url = MkUrl.run(mkUrl, req);
        const sentryTransaction = Sentry.startTransaction({
            name: `${method} ${url}`,
        });

        try {
            const body = mkBody(req);
            const newInit: RequestInit & { headers: Headers } = {
                method,
                ...init,
                headers: new Headers(init.headers),
            };
            if (body !== undefined) {
                if (body instanceof FormData) {
                    newInit.body = body;
                } else {
                    newInit.body = body.toRequestBody();
                    newInit.headers.set("content-type", body.contentType());
                }
            }

            return await f(url, newInit);
        } finally {
            sentryTransaction?.finish?.();
        }
    };

export type FetcherScope<scope = unknown> = {
    raw: <req>(
        ...args: FetcherArgs<scope & req>
    ) => (req: scope & req) => Promise<Response>;
    json: <req, res>(
        ...args: FetcherArgs<scope & req>
    ) => (req: scope & req) => Promise<res>;
    errorAware: <req, res>(
        ...args: FetcherArgs<scope & req>
    ) => (req: scope & req) => Promise<ApiResponse<res>>;
};

export type Fetcher<scope = unknown> = {
    scoped: <scope2>(
        scope: MkUrl<scope & scope2>
    ) => Fetcher<scope & scope2> & { scope: MkUrl<scope & scope2> };
    protected: FetcherScope<scope>;
} & FetcherScope<scope>;

const scopedReq = <scopeReq, req>(
    scope: MkUrl<scopeReq> | undefined,
    mkUrl: MkUrl<scopeReq & req>
): MkUrl<scopeReq & req> =>
    scope == null ? mkUrl : MkUrl.concat(scope, mkUrl);

const fetcher: Fetcher = {
    scoped: <scopeReq>(
        scope?: MkUrl<scopeReq>
    ): Fetcher<scopeReq> & { scope: MkUrl<scopeReq> } => ({
        scope: scope ?? MkUrl.empty,
        scoped: <scope2Req>(scope2: MkUrl<scopeReq & scope2Req>) =>
            fetcher.scoped<scopeReq & scope2Req>(
                scope == null
                    ? scope2
                    : scopedReq<scopeReq, scope2Req>(scope, scope2)
            ),
        protected: {
            raw: <req>(...[mkUrl, ...args]: FetcherArgs<scopeReq & req>) =>
                fetcher.protected.raw(
                    scopedReq<scopeReq, req>(scope, mkUrl),
                    ...args
                ),
            json: <req, res>(
                ...[mkUrl, ...args]: FetcherArgs<scopeReq & req>
            ) =>
                fetcher.protected.json<scopeReq & req, res>(
                    scopedReq<scopeReq, req>(scope, mkUrl),
                    ...args
                ),
            errorAware: <req, res>(
                ...[mkUrl, ...args]: FetcherArgs<scopeReq & req>
            ) =>
                fetcher.protected.errorAware<scopeReq & req, res>(
                    scopedReq<scopeReq, req>(scope, mkUrl),
                    ...args
                ),
        },
        raw: <req>(...[mkUrl, ...args]: FetcherArgs<scopeReq & req>) =>
            fetcher.raw(scopedReq<scopeReq, req>(scope, mkUrl), ...args),
        json: <req, res>(...[mkUrl, ...args]: FetcherArgs<scopeReq & req>) =>
            fetcher.json<scopeReq & req, res>(
                scopedReq<scopeReq, req>(scope, mkUrl),
                ...args
            ),
        errorAware: <req, res>(
            ...[mkUrl, ...args]: FetcherArgs<scopeReq & req>
        ) =>
            fetcher.errorAware<scopeReq & req, res>(
                scopedReq<scopeReq, req>(scope, mkUrl),
                ...args
            ),
    }),

    protected: {
        raw: <req>(...args: FetcherArgs<req>) =>
            mkReq<req, Response>(
                (request: RequestInfo, init: RequestInit = {}) =>
                    apiFetchProtected(request, init, true)
            )(...args),

        json: <req, res>(...args: FetcherArgs<req>) =>
            mkReq<req, res>(async (req, init) => {
                init.headers.set("accept", "application/json");

                return (await apiFetchProtected(req, init, true)).json();
            })(...args),

        errorAware: <req, res>(...args: FetcherArgs<req>) =>
            mkReq<req, ApiResponse<res>>(errorAwareApiFetchProtected)(...args),
    },

    raw: <req>(...args: FetcherArgs<req>) =>
        mkReq<req, Response>(apiFetch)(...args),

    json: <req, res>(...args: FetcherArgs<req>) =>
        mkReq<req, res>(async (req, init) => {
            init.headers.set("Accept", "application/json");

            return (await apiFetch(req, init)).json();
        })(...args),

    errorAware: <req, res>(...args: FetcherArgs<req>) =>
        mkReq<req, ApiResponse<res>>(errorAwareApiFetch)(...args),
};
export default fetcher;
