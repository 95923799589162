import React, { FC } from "react";

// eslint-disable-next-line import/no-unresolved,import/no-webpack-loader-syntax
import bargeldAusZahlung from "!!svg-inline-loader!./bargeldauszahlung.svg";
// eslint-disable-next-line import/no-unresolved,import/no-webpack-loader-syntax
import coffee from "!!svg-inline-loader!./coffee.svg";
// eslint-disable-next-line import/no-unresolved,import/no-webpack-loader-syntax
import gas from "!!svg-inline-loader!./gas.svg";
// eslint-disable-next-line import/no-unresolved,import/no-webpack-loader-syntax
import pkwTransporter from "!!svg-inline-loader!./Icons_Web_PKW_Transporter.svg";
// eslint-disable-next-line import/no-unresolved,import/no-webpack-loader-syntax
import licens from "!!svg-inline-loader!./licens.svg";
// eslint-disable-next-line import/no-unresolved,import/no-webpack-loader-syntax
import lkwBigger12t from "!!svg-inline-loader!./lkw-bigger-12t.svg";
// eslint-disable-next-line import/no-unresolved,import/no-webpack-loader-syntax
import lkwSmaller12t from "!!svg-inline-loader!./lkw-smaller-12t.svg";
// eslint-disable-next-line import/no-unresolved,import/no-webpack-loader-syntax
import lkwWash from "!!svg-inline-loader!./lkw-wash.svg";
// eslint-disable-next-line import/no-unresolved,import/no-webpack-loader-syntax
import maut from "!!svg-inline-loader!./maut.svg";
// eslint-disable-next-line import/no-unresolved,import/no-webpack-loader-syntax
import pkwWash from "!!svg-inline-loader!./pkw-wash.svg";
// eslint-disable-next-line import/no-unresolved,import/no-webpack-loader-syntax
import restaurant from "!!svg-inline-loader!./restaurant.svg";
// eslint-disable-next-line import/no-unresolved,import/no-webpack-loader-syntax
import restroom from "!!svg-inline-loader!./restroom.svg";
// eslint-disable-next-line import/no-unresolved,import/no-webpack-loader-syntax
import sbWaschbox from "!!svg-inline-loader!./sb-waschbox.svg";
// eslint-disable-next-line import/no-unresolved,import/no-webpack-loader-syntax
import shop from "!!svg-inline-loader!./shop.svg";
// eslint-disable-next-line import/no-unresolved,import/no-webpack-loader-syntax
import shower from "!!svg-inline-loader!./shower.svg";
// eslint-disable-next-line import/no-unresolved,import/no-webpack-loader-syntax
import travis from "!!svg-inline-loader!./travis.svg";
// eslint-disable-next-line import/no-unresolved,import/no-webpack-loader-syntax
import truckParking from "!!svg-inline-loader!./truck-parking.svg";
// eslint-disable-next-line import/no-unresolved,import/no-webpack-loader-syntax
import wheelchair from "!!svg-inline-loader!./wheelchair.svg";
import styled from "@emotion/styled";
import classNames from "classnames";

import { NormalServiceId } from "../../types/index";
import * as NormalOrTravis from "../../types/NormalOrTravis";
import * as P from "../../types/Property";

const normalServiceIcons: Record<NormalServiceId, string> = {
    [9 as NormalServiceId]: truckParking,
    [18 as NormalServiceId]: wheelchair,
    [20 as NormalServiceId]: restroom,
    [24 as NormalServiceId]: shower,
    [2 as NormalServiceId]: coffee,
    [28 as NormalServiceId]: licens,
    [8 as NormalServiceId]: lkwWash, // LKW Waschanlage
    [11 as NormalServiceId]: gas,
    [21 as NormalServiceId]: maut,
    [4 as NormalServiceId]: restaurant,
    [7 as NormalServiceId]: pkwWash,
    [29 as NormalServiceId]: bargeldAusZahlung,
    [10 as NormalServiceId]: sbWaschbox,
    [31 as NormalServiceId]: lkwSmaller12t,
    [32 as NormalServiceId]: lkwBigger12t,
    [30 as NormalServiceId]: pkwTransporter,
    [5 as NormalServiceId]: shop,
};

const typeIcons = {
    travis, // Travis
};

const SvgContainerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100;
`;

const SvgContainer = styled.div`
    display: contents;
    color: ${({ theme }) => theme.colors.blue.toString()};

    &.travis {
        color: #2dbce6;
    }

    .is-selected & {
        color: currentColor;
    }

    & > svg {
        width: 32px;
    }
`;

const Text = styled.div`
    font-size: 0.875rem;
    text-align: center;
`;

export type PropertyProps = {
    entry: [P.PropertyId, string];
    targetId: string;
};

const Property: FC<PropertyProps> = ({
    entry: [prop, text],
    targetId: _,
    ...props
}) => {
    const icon =
        P.propertyIdIsServicePropertyId(prop) &&
        NormalOrTravis.isNormal(prop.value)
            ? normalServiceIcons[prop.value.value]
            : typeIcons.travis;

    return (
        <SvgContainerWrapper>
            <div {...props} aria-hidden="true" title={text}>
                {icon ? (
                    <SvgContainer
                        className={classNames({
                            travis: P.propertyIdIsTravisPropertyId(prop),
                        })}
                        dangerouslySetInnerHTML={{ __html: icon }}
                    />
                ) : (
                    text
                )}
            </div>

            <Text>
                {text === "behindertengerecht" ? (
                    <>behinderten&shy;gerecht</>
                ) : (
                    text
                        .replace(/\bPKW\b/g, "Pkw")
                        .replace(/\bLKW\b/g, "Lkw")
                        .replace(" / ", "/")
                )}
            </Text>
        </SvgContainerWrapper>
    );
};
export default Property;
