import React, { FC, useMemo } from "react";

import { pipe } from "fp-ts/lib/function";
import * as Mp from "fp-ts/lib/ReadonlyMap";
import * as St from "fp-ts/lib/ReadonlySet";
import * as str from "fp-ts/lib/string";

import { Columns } from "react-bulma-components";

import styled from "@emotion/styled";

import Headline from "../../../base/atoms/Headline";
import { activeLink } from "../../../shared/activeLink";
import { Switch } from "../../../shared/Switch";
import {
    Tab,
    TabBar,
    TabbedControlled,
    TabContent,
} from "../../../shared/Tabs";
import useGasStations, {
    AcceptancePartnerStationType,
    AcceptancePartnerStationTypeOrd,
    defaultMapById,
    HoyerStationType,
    HoyerStationTypeOrd,
    mapById,
    PaymentOrd,
    PaymentType,
} from "../store";
import * as FT from "../types/FuelType";
import * as P from "../types/Property";
import * as S from "../types/Service";
import { TravisServiceId } from "../types/TravisService";
import Chip from "./Chip";
import ColMultiSelect from "./ColMultiSelect";
import FuelType from "./FuelType";
import Property from "./Property";
import * as styles from "./style.module.scss";

const TabContentWrapper = styled.div`
    margin-top: 20px;
    margin-bottom: 10px;

    ${({ theme }) => theme.breakpoints.from.normal.css`
        margin-top: 30px;
        margin-bottom: 30px;
    `}
`;

const Tabs = styled(TabbedControlled)`
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    ${({ theme }) => theme.breakpoints.upTo.small.css`
       ${theme.paddings.containerInner.left}
       ${theme.paddings.containerInner.right}
    `}

    ${TabBar} {
        flex-direction: row;
        column-gap: 0;
        justify-content: space-between;
        max-width: 100%;
        ${({ theme }) => theme.breakpoints.from.small.css`
            justify-content: start;
        `};
    }

    ${TabContent} {
        margin-top: 0;
    }

    @media print, screen and (width >= 768px) {
        ${Tab} {
            all: unset !important;
            width: 20% !important;

            & > * {
                font-weight: 900;
                display: inline-block;
                font-size: 1em;
                ${({ theme }) => theme.breakpoints.from.medium.css`
                    font-size: 1.5em;
                `}
            }
            ${({ theme }) => activeLink({ theme, target: "& > *" })}
        }

        .column.is-2_4,
        .column.is-2_4-tablet,
        ${Tab} {
            flex: none;
            width: 20%;
        }
    }
`;

const StyledHeadline = styled(Headline)`
    color: ${({ theme }) => theme.colors.blue.toString()};
`;

export type FiltersProps = Record<string, never>;

const Filters: FC<FiltersProps> = () => {
    const {
        availableOptions,
        filter,
        setFilterFuelTypes,
        setFilterProperties,
        setFilterHoyerStationTypes,
        setFilterAcceptancePartnerStationTypes,
        setFilterPremium,
        setFilterPayments,
    } = useGasStations((state) => ({
        availableOptions: state.availableOptions,
        gasStations: state.gasStations,
        filter: state.filter,
        geolocation: state.geolocation,
        newLocation: state.newLocation,
        newRouteEnd: state.newRouteEnd,
        isSelectingRoute: state.isSelectingRoute,
        setLocation: state.setLocation,
        setLocationFromPlace: state.setLocationFromPlace,
        setRouteEnd: state.setRouteEnd,
        setRouteEndFromPlace: state.setRouteEndFromPlace,
        setFilterFuelTypes: state.setFilterFuelTypes,
        setFilterProperties: state.setFilterProperties,
        setFilterHoyerStationTypes: state.setFilterHoyerStationTypes,
        setFilterAcceptancePartnerStationTypes:
            state.setFilterAcceptancePartnerStationTypes,
        setFilterPremium: state.setFilterPremium,
        setFilterPayments: state.setFilterPayments,
        setFilterCircumscribedAreaRadius:
            state.setFilterCircumscribedAreaRadius,
        resetFilter: state.resetFilter,
        setMapCenterFromGeolocation: state.setMapCenterFromGeolocation,
        startSelectingRoute: state.startSelectingRoute,
        stopSelectingRoute: state.stopSelectingRoute,
    }));

    const tabs = useMemo(
        () =>
            Object.fromEntries(
                Object.entries({
                    fuelTypes: <div>Kraftstoffe</div>,
                    services: <div>Services</div>,
                    gasStations: <div>Tankstellen</div>,
                })
            ),
        []
    );

    const fuelTypes = useMemo(() => {
        return pipe(
            availableOptions.data?.fuelTypes ?? new Set<FT.FuelType>(),
            (vs) => defaultMapById(FT.Ord, FT.IdOrd, vs),
            Mp.map((ft) => ft.name)
        );
    }, [availableOptions]);

    const properties = useMemo(() => {
        const propName = (p: P.Property) =>
            P.propertyIsTravisProperty(p)
                ? "Travis"
                : S.serviceIsTravisService(p.value)
                ? { [TravisServiceId.tankCleaning]: "Tankreinigung" }[
                      p.value.id.value
                  ]
                : p.value.name;

        return pipe(
            availableOptions.data?.properties ?? new Set<P.Property>(),
            (vs) => mapById(P.Ord, P.IdOrd, vs, P.propertyId),
            Mp.map(propName)
        );
    }, [availableOptions]);

    return (
        <Tabs defaultSelected="fuelTypes" {...{ tabs }}>
            {{
                fuelTypes: (
                    <TabContentWrapper>
                        <ColMultiSelect<FT.FuelTypeId>
                            colSize={"2_4" as any}
                            mobileColSize={6}
                            id="finder-fuelTypes"
                            value={filter.fuelTypes}
                            values={fuelTypes}
                            onChange={setFilterFuelTypes}
                            mkOptionValue={(id) => id.toString()}
                            Option={FuelType}
                            gtag="gasStations:finder:filter:fuelTypes"
                            Ord={FT.IdOrd}
                            noInput
                        />
                    </TabContentWrapper>
                ),
                services: (
                    <TabContentWrapper>
                        <ColMultiSelect<P.PropertyId>
                            colSize="one-quarter"
                            mobileColSize="one-third"
                            id="finder-properties"
                            value={filter.properties}
                            values={properties}
                            onChange={setFilterProperties}
                            mkOptionValue={P.foldId(
                                (s) => `${s.type}-${s.value}`,
                                () => "travis"
                            )}
                            optionClassName={styles.multiSelectOptionButton}
                            Option={Property}
                            gtag="gasStations:finder:filter:properties"
                            centeredColumns
                            noInput
                            Ord={P.IdOrd}
                        />
                    </TabContentWrapper>
                ),
                gasStations: (
                    <TabContentWrapper>
                        <Columns breakpoint="mobile">
                            <Columns.Column
                                mobile={{ size: 12 }}
                                tablet={{ size: "2_4" as any }}
                            >
                                <StyledHeadline level={3} levelStyle={4}>
                                    Hoyer
                                </StyledHeadline>

                                <ColMultiSelect<HoyerStationType>
                                    colSize={12}
                                    mobileColSize={12}
                                    id="finder-hoyerStationTypes"
                                    value={filter.hoyerStationTypes}
                                    values={
                                        new Map<HoyerStationType, string>([
                                            [HoyerStationType.all, "Alle"],
                                            [
                                                HoyerStationType.gasStation,
                                                "Tankstelle",
                                            ],
                                            [
                                                HoyerStationType.vendingMachine,
                                                "Automat",
                                            ],
                                        ])
                                    }
                                    onChange={(
                                        sts: ReadonlySet<HoyerStationType>
                                    ) => {
                                        if (
                                            filter.hoyerStationTypes.has(
                                                HoyerStationType.all
                                            )
                                        ) {
                                            setFilterHoyerStationTypes(
                                                St.remove(HoyerStationTypeOrd)(
                                                    HoyerStationType.all
                                                )(sts)
                                            );
                                        } else {
                                            setFilterHoyerStationTypes(sts);
                                        }
                                    }}
                                    gtag="gasStations:finder:filter:hoyerStationTypes"
                                    Ord={str.Ord}
                                    Option={Chip}
                                    noInput
                                />
                            </Columns.Column>

                            <Columns.Column
                                mobile={{ size: 12 }}
                                tablet={{ size: "2_4" as any }}
                            >
                                <StyledHeadline level={3} levelStyle={5}>
                                    Akzeptanzpartner
                                </StyledHeadline>

                                <ColMultiSelect<AcceptancePartnerStationType>
                                    colSize={12}
                                    mobileColSize={12}
                                    id="finder-acceptancePartnerStationTypes"
                                    value={
                                        filter.premium
                                            ? St.remove(
                                                  AcceptancePartnerStationTypeOrd
                                              )(
                                                  AcceptancePartnerStationType.all
                                              )(
                                                  filter.acceptancePartnerStationTypes
                                              )
                                            : filter.acceptancePartnerStationTypes
                                    }
                                    values={
                                        new Map<
                                            AcceptancePartnerStationType,
                                            string
                                        >([
                                            [
                                                AcceptancePartnerStationType.all,
                                                "Alle",
                                            ],
                                            [
                                                AcceptancePartnerStationType.gasStation,
                                                "Tankstelle",
                                            ],
                                            [
                                                AcceptancePartnerStationType.vendingMachine,
                                                "Automat",
                                            ],
                                        ])
                                    }
                                    onChange={(
                                        sts: ReadonlySet<AcceptancePartnerStationType>
                                    ) => {
                                        if (
                                            filter.premium &&
                                            sts.size === 1 &&
                                            sts.has(
                                                AcceptancePartnerStationType.all
                                            )
                                        ) {
                                            setFilterPremium(false);
                                            return;
                                        }
                                        if (
                                            filter.acceptancePartnerStationTypes.has(
                                                AcceptancePartnerStationType.all
                                            )
                                        ) {
                                            setFilterAcceptancePartnerStationTypes(
                                                St.remove(
                                                    AcceptancePartnerStationTypeOrd
                                                )(
                                                    AcceptancePartnerStationType.all
                                                )(sts)
                                            );
                                        } else {
                                            setFilterAcceptancePartnerStationTypes(
                                                sts
                                            );
                                        }
                                    }}
                                    gtag="gasStations:finder:filter:acceptancePartnerStationTypes"
                                    Ord={AcceptancePartnerStationTypeOrd}
                                    Option={Chip}
                                    noInput
                                />

                                <Switch
                                    isSelected={filter.premium}
                                    onChange={setFilterPremium}
                                >
                                    Nur Premium-Station
                                </Switch>
                            </Columns.Column>
                            <Columns.Column
                                mobile={{ size: 12 }}
                                tablet={{ size: "2_4" as any }}
                            >
                                <StyledHeadline level={3} levelStyle={5}>
                                    Bezahlung Hoyer Card
                                </StyledHeadline>

                                <ColMultiSelect<PaymentType>
                                    colSize={12}
                                    mobileColSize={12}
                                    id="finder-payment"
                                    value={filter.paymentTypes}
                                    values={
                                        new Map<PaymentType, string>([
                                            [
                                                PaymentType.hoyerApp,
                                                "Hoyer Pay (App)",
                                            ],
                                            [
                                                PaymentType.hoyerCard,
                                                "Physische Karte",
                                            ],
                                        ])
                                    }
                                    onChange={setFilterPayments}
                                    gtag="gasStations:finder:filter:payment"
                                    Ord={PaymentOrd}
                                    Option={Chip}
                                    noInput
                                />
                            </Columns.Column>
                        </Columns>
                    </TabContentWrapper>
                ),
            }}
        </Tabs>
    );
};

export default Filters;
