import React, { RefAttributes } from "react";

import {
    type SwitchProps as BaseSwitchProps,
    Switch as BaseSwitch,
} from "react-aria-components";
import { FormattedMessage } from "react-intl";

import styled from "@emotion/styled";

import { defaultPropsHasDisabled, HasDisabled } from "../../types/HasDisabled";

const StyledSwitch = styled(BaseSwitch)`
    --Switch--itemSelected: 0;

    --Switch--idle__Indicator--background: ${({ theme }) =>
        theme.colors.grey.darken(0.25).toString()};
    --Switch--selected__Indicator--background: ${({ theme }) =>
        theme.colors.green.toString()};

    --Switch--idle__IndicatorDot--background: ${({ theme }) =>
        theme.colors.white.toString()};
    --Switch--selected__IndicatorDot--background: ${({ theme }) =>
        theme.colors.grey.toString()};

    --Switch__Indicator--padding: 0.0715rem;
    --Switch__Indicator--height: calc(
        var(--Switch__Indicator--padding) * 2 +
            var(--Switch__IndicatorDot--size)
    );

    --Switch__IndicatorDot--size: 0.857rem;

    --Switch__Indicator--background: var(--Switch--idle__Indicator--background);
    --Switch__IndicatorDot--background: var(
        --Switch--idle__IndicatorDot--background
    );
    &[data-selected] {
        --Switch--itemSelected: 1;

        --Switch__Indicator--background: var(
            --Switch--selected__Indicator--background
        );
        --Switch__IndicatorDot--background: var(
            --Switch--selected__IndicatorDot--background
        );
    }

    cursor: pointer;
    &[data-disabled="true"] {
        cursor: default;
    }
`;

const Indicator = styled.div`
    width: calc(
        var(--Switch__Indicator--padding) * 2 +
            var(--Switch__IndicatorDot--size) * 2
    );
    height: var(--Switch__Indicator--height);
    background: var(--Switch__Indicator--background);
    border-radius: var(--Switch__Indicator--height);
    transition: all 200ms;

    &::before {
        content: "";
        display: block;
        margin: var(--Switch__Indicator--padding);
        width: var(--Switch__IndicatorDot--size);
        height: var(--Switch__IndicatorDot--size);
        background: var(--Switch__IndicatorDot--background);
        border-radius: 16px;
        transition: all 200ms;
        transform: translateX(calc(100% * var(--Switch--itemSelected)));
        filter: brightness(100%);

        ${StyledSwitch}[data-pressed] & {
            filter: brightness(90%);
        }
    }

    ${StyledSwitch}[data-focus-visible] & {
        outline: 2px solid var(--focus-ring-color);
        outline-offset: 2px;
    }
`;

const IndicatorGroup = styled.div`
    display: flex;
    align-items: center;
    gap: 0.571rem;

    ${StyledSwitch}[data-disabled="true"] & {
        opacity: 0.5;
    }
`;

const IndicatorLabel = styled.span`
    min-width: 2.5em;
    text-align: center;
`;

const IndicatorOffLabel = styled(IndicatorLabel)`
    ${StyledSwitch}:not([data-selected]) & {
        font-weight: bold;
    }
`;

const IndicatorOnLabel = styled(IndicatorLabel)`
    ${StyledSwitch}[data-selected] & {
        font-weight: bold;
    }
`;

export type OwnSwitchProps = {
    defaultSelected?: boolean;
    isSelected?: boolean;
    onChange?: (isSelected: boolean) => void;
};

export type SwitchProps = OwnSwitchProps & HasDisabled & BaseSwitchProps;

export const Switch = ({
    children,
    ...props
}: SwitchProps & RefAttributes<HTMLLabelElement>) => (
    <StyledSwitch className="react-aria-Switch" {...props}>
        {children}

        <IndicatorGroup>
            <IndicatorOffLabel aria-hidden={true}>
                <FormattedMessage
                    id="atoms.Switch.labels.off"
                    defaultMessage="Aus"
                />
            </IndicatorOffLabel>

            <Indicator />

            <IndicatorOnLabel aria-hidden={true}>
                <FormattedMessage
                    id="atoms.Switch.labels.on"
                    defaultMessage="Ein"
                />
            </IndicatorOnLabel>
        </IndicatorGroup>
    </StyledSwitch>
);
Switch.defaultProps = {
    ...defaultPropsHasDisabled,
};
