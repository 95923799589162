import React, { FC } from "react";

import * as FT from "../types/FuelType";
import Chip, { ChipProps } from "./Chip";

const knownShortNames: Record<number, string> = {
    5: "LPG Pkw",
    10: "Super Plus",
    13: "CNG Erdgas",
    14: "LPG Lkw",
    24: "LNG",
};

const shortName = (name: string) =>
    name
        .replace(/\bFuture Power\b/g, "FP")
        .replace(/ für /, " ")
        .replace(/\bSuper 95\b/g, "Super")
        .replace(/\bLadestrom\b/g, "Strom");

export type FuelType = ChipProps<FT.FuelTypeId>;

const FuelType: FC<FuelType> = ({ entry: [fuelType, name], ...props }) => {
    const short = (knownShortNames[fuelType] ?? shortName(name))
        .replace(/\bPKW\b/g, "Pkw")
        .replace(/\bLKW\b/g, "Lkw");

    return <Chip entry={[fuelType, short]} {...props} />;
};
export default FuelType;
